<template>
  <div class="maintenance-contaienr d-flex align-center">
    <div class="title d-flex flex-column align-center justify-start">
      <h3 style="font-size: 2rem;">WE ARE COMMING SOON</h3>
      <span>The website under maintenance!</span>
    </div>
    <img :src="sourceImage" alt="person wait" class="maintenance-image"/>
  </div>
</template>
<script>
export default {
  data() {
    return {
      sourceImage: require('@/assets/images/3d-characters/error.png'),
    }
  }
}
</script>
<style lang="scss" scoped>
@media screen and (max-width: 600px) {
  .maintenance-contaienr {
    flex-direction: column;
    .title {
      margin: 20px 0;
      width: 100% !important;
    }
    .maintenance-image {
      width: 100% !important;
      margin-top: 60px;
    }
  }

}
.maintenance-contaienr {
  width: 100vw;
  height: 100vh;
  .title {
    width: 50%;
  }
  .maintenance-image {
    width: 60%;
  }
}
</style>
